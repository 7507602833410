import { useIFetch } from '~/composables/useIFetch';
import apiRoutes from '~/const/apiRoutes';

export const useReimbursableStore = defineStore('reimbursableStore', () => {
   const reimbursableInvoiceList = ref([]);
   const reimbursableLoading = ref(false);
   const totalReimbursableCount = ref(0);
   const remaingReimbursableCount = ref(0);
   const getReimbursableInvoiceList = async (currentPage) => {
      reimbursableLoading.value = true;
      const { data, error } = await useIFetch(
         apiRoutes.stripe.reimbursableList(currentPage || '')
      );
      if (data?.value) {
         reimbursableLoading.value = false;
         if (currentPage > 1) {
            remaingReimbursableCount.value = data.value?.meta?.total;
         } else {
            totalReimbursableCount.value = data.value?.meta?.total;
            remaingReimbursableCount.value = data.value?.meta?.total;
         }
         reimbursableInvoiceList.value = data.value?.data;
      }
      if (error?.value) {
         reimbursableLoading.value = false;
      }
   };
   const invoiceDetail = ref({});
   const setInvoiceDetail = (data) => {
      invoiceDetail.value = data || null;
   };
   return {
      getReimbursableInvoiceList,
      reimbursableInvoiceList,
      reimbursableLoading,
      totalReimbursableCount,
      remaingReimbursableCount,
      invoiceDetail,
      setInvoiceDetail,
   };
});
