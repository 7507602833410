<template>
   <!-- <ClientAccountInvoiceDetail></ClientAccountInvoiceDetail> -->
   <div class="grid place-content-center grid-cols-12">
      <div class="col-span-12 text-lg my-6">
         {{ t('plan.reimbursable') + ' ' + t('reimbursableJourney.invoice') }}
      </div>
      <div v-if="reimbursableLoading" class="col-span-12">
         <CommonLoader class="mb-3" v-for="i in 3" :key="i"></CommonLoader>
      </div>
      <div
         v-else-if="!reimbursableInvoiceList.length"
         class="col-span-12 justify-center flex-col"
      >
         <div class="w-full mx-auto text-center sm:w-1/3">
            <img alt="no-data" class="mx-auto h-16 w-16" :src="noDataImage" />

            <p class="mt-3 text-xs text-gray-200">
               {{ $t('reimbursableJourney.noDataText') }}
            </p>
         </div>
         <!-- <div class="sm:w-7/12 w-full mx-auto">
            <div
               class="bg-primary-400 bg-opacity-10 border border-primary-500 rounded-lg mt-6 w-full px-6 py-5"
            >
               <div class="flex items-center gap-2">
                  <InformationCircleIcon class="h-6 w-6 text-primary-500" />
                  <span class="text-primary-500 text-xm"
                     >{{ $t('reimbursableJourney.annoucementTitle') }}
                  </span>
               </div>
               <div class="text-left text-xm ms-8">
                  {{ $t('reimbursableJourney.annoucement') }}
               </div>
            </div>
         </div> -->
      </div>
      <div v-else class="col-span-12">
         <CommonTable
            :headers="headers"
            :data="reimbursableInvoiceList"
            :total-count="totalReimbursableCount"
            v-model:page="currentPage"
            :remaining-data-count="remaingReimbursableCount"
            @next-page="currentPage = currentPage + 1"
            @prev-page="currentPage = currentPage - 1"
            from-reimbursable
         >
            <template #booking_date="{ data }">
               {{ dayjs(data?.session?.booking_date).format('DD MMMM YYYY') }}
            </template>
            <template #session_date="{ data }">
               <div class="-ms-2">
                  {{
                     dayjs(data?.session?.session_date).format('DD MMMM YYYY')
                  }}
               </div>
            </template>
            <template #action="{ data }">
               <div
                  @click="setInvoiceDetail(data)"
                  class="border inline-flex text-wrap gap-2 text-xs border-green-500 rounded-full py-1 px-2 cursor-pointer bg-green-300 bg-opacity-10 text-green-500"
               >
                  View Invoice
                  <ArrowTopRightOnSquareIcon class="h-4 w-4 text-green-500" />
               </div>
            </template>
         </CommonTable>
      </div>
   </div>
</template>
<script setup>
   import { useIFetch } from '@/composables/useIFetch';
   import dayjs from 'dayjs';
   import {
      ArrowTopRightOnSquareIcon,
      InformationCircleIcon,
   } from '@heroicons/vue/24/outline';
   import BaseButton from '~/components/base/BaseButton.vue';
   import apiRoutes from '~/const/apiRoutes';
   import noDataImage from '@/assets/image/empty.svg';
   const { useDateTime } = useTimeComposable();
   const { t } = useI18n();
   const emit = defineEmits(['show-detail']);

   const headers = ref([
      { title: t('reimbursableJourney.bookingDate'), value: 'booking_date' },
      { title: t('reimbursableJourney.sessionDate'), value: 'session_date' },
      { title: t('sessions.action'), value: 'action' },
   ]);

   const currentPage = ref(1);
   import { useReimbursableStore } from '@/stores/reimbursableInfo.ts';
   const {
      reimbursableInvoiceList,
      totalReimbursableCount,
      remaingReimbursableCount,
      reimbursableLoading,
   } = storeToRefs(useReimbursableStore());

   watch(currentPage, async () => {
      useReimbursableStore().getReimbursableInvoiceList(currentPage.value);
   });

   onMounted(() => {
      useReimbursableStore().getReimbursableInvoiceList(currentPage.value);
   });
   const setInvoiceDetail = (data) => {
      useReimbursableStore().setInvoiceDetail(data);
      emit('show-detail');
   };
</script>
