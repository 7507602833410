<script setup lang="ts">
   const showInvoiceList = ref(true);
</script>
<template>
   <div v-if="showInvoiceList">
      <ClientAccountReimbursableInvoices
         @show-detail="showInvoiceList = !showInvoiceList"
      />
   </div>
   <div v-else>
      <ClientAccountReimbursableInvoiceDetail
         @show-list="showInvoiceList = true"
      />
   </div>
</template>
