<template>
   <div>
      <div class="my-3">
         <BaseButton @click="emit('show-list')" color="black" variant="plain">
            <span class="mr-2">←</span> Back
         </BaseButton>
      </div>
      <div class="grid grid-cols-12 gap-4 pb-8">
         <div
            class="border order-1 border-gray-100 border-opacity-40 sm:col-span-9 col-span-12"
         >
            <div id="invoice-content" class="bg-white col-span-12 p-6">
               <div class="flex px-4 items-start justify-between">
                  <div>
                     <div
                        class="text-3xl font-medium uppercase mb-2 text-gray-800"
                     >
                        Invoice
                     </div>
                     <p class="text-xs text-gray-600">
                        #{{ invoiceDetail?.invoice_id }}
                     </p>
                  </div>
                  <div>
                     <span class="mb-2 text-sm font-medium">Invoice Date:</span>
                     <span class="text-xs ms-2 text-gray-600">
                        {{ dayjs(invoiceDetail?.date).format('DD MMMM, YYYY') }}
                     </span>
                  </div>
                  <div>
                     <img
                        src="/public/image/logo.svg"
                        width="164"
                        alt="checklist"
                     />
                  </div>
               </div>

               <div
                  class="grid gap-2 grid-cols-12 pb-3 sm:pb-0 border-b border-gray-100 border-opacity-40 text-gray-1000 px-4 my-4 justify-between"
               >
                  <div class="sm:pe-4 mb-6 col-span-4 sm:col-span-4">
                     <div class="mb-2 font-medium">Billed To:</div>
                     <div class="text-xs text-gray-600">
                        <div>
                           <span class="font-medium text-gray-1000"
                              >Name:
                           </span>
                           <span>{{ invoiceDetail?.client?.name }}</span>
                        </div>

                        <div
                           class="my-2"
                           v-if="invoiceDetail?.client?.account_id"
                        >
                           <span class="font-medium text-gray-1000"
                              >Account ID:
                           </span>
                           <span>{{ invoiceDetail?.client?.account_id }}</span>
                        </div>
                        <div
                           v-if="invoiceDetail?.client?.national_id"
                           class="mb-2"
                        >
                           <span class="font-medium text-gray-1000"
                              >Emirates ID:
                           </span>
                           <span>{{
                              invoiceDetail?.client?.national_id || '-'
                           }}</span>
                        </div>
                        <div
                           v-if="invoiceDetail?.client?.date_of_birth"
                           class="mb-2"
                        >
                           <span class="font-medium text-gray-1000">DOB: </span>
                           <span v-if="invoiceDetail?.client?.date_of_birth">
                              {{
                                 dayjs(
                                    invoiceDetail?.client?.date_of_birth
                                 ).format('DD MMMM, YYYY')
                              }}
                           </span>
                           <span v-else> - </span>
                        </div>
                        <div>
                           <span class="font-medium text-gray-1000"
                              >Country:
                           </span>
                           <span>{{ invoiceDetail?.client?.country }}</span>
                        </div>
                     </div>
                  </div>
                  <div
                     class="sm:ps-8 pe-2 col-span-4 sm:col-span-4 text-sm text-left pb-10 border-gray-100 border-opacity-40 sm:border-l"
                  >
                     <div class="mb-2 mt-2 text-sm font-medium">
                        Therapist Details:
                     </div>
                     <div class="mb-2 text-xs">
                        <p class="text-gray-600">
                           <span class="font-medium text-gray-900">
                              Name:
                           </span>
                           {{ invoiceDetail?.therapist?.name }}
                        </p>
                     </div>
                     <div
                        v-if="invoiceDetail?.therapist?.license"
                        class="text-xs mb-2"
                     >
                        <p class="text-gray-600">
                           <span class="font-medium text-gray-900">
                              License:
                           </span>
                           {{ invoiceDetail?.therapist?.license || '-' }}
                        </p>
                     </div>
                     <div class="text-xs">
                        <p class="text-gray-600">
                           <span class="font-medium text-gray-900">
                              Country:
                           </span>
                           {{ invoiceDetail?.therapist?.country || 'UAE' }}
                        </p>
                     </div>
                  </div>
                  <div
                     class="sm:ps-8 col-span-4 sm:col-span-4 text-sm sm:border-l border-gray-100 border-opacity-40"
                  >
                     <div class="mb-2 font-medium">
                        TalkTime Mental Health Services Limited
                     </div>
                     <div class="text-gray-600 pb-6 text-xs">
                        <div class="my-2">Unit 201</div>
                        Level 1, Gate Avenue,
                        <div class="my-2">South Zone, DIFC,</div>
                        Dubai- UAE
                     </div>
                  </div>
               </div>
               <div
                  class="mt-5 px-4 pb-5 text-xs border-gray-100 border-opacity-40 border-b"
               >
                  <div class="font-medium mb-5">Coverage Information:</div>
                  <div class="text-xs list-disc ms-4 text-gray-600">
                     <div class="flex items-center gap-2">
                        <span class="text-2xl text-black -mt-3 font-medium"
                           >.</span
                        >
                        <span class="font-medium text-gray-800 text-xs"
                           >Coverage Type:
                        </span>
                        {{
                           invoiceDetail?.coverage?.type ||
                           'Reimbusable Insurance'
                        }}
                     </div>
                     <div class="my-3 flex items-center gap-2">
                        <span class="text-2xl -mt-3 text-black font-medium"
                           >.</span
                        >
                        <span class="font-medium text-gray-800 text-xs"
                           >Insurance Provider:
                        </span>
                        {{ invoiceDetail?.coverage?.provider || 'Cigna' }}
                     </div>
                     <div class="flex items-center gap-2">
                        <span class="text-2xl text-black -mt-3 font-medium"
                           >.</span
                        >
                        <span class="font-medium w-28 text-gray-800 text-xs"
                           >Client Concern/s:
                        </span>
                        <span>
                           {{
                              invoiceDetail?.coverage?.concernssss ||
                              'I no longer enjoy pleasurable activates, I lost someone close to me, I lost someone close to me'
                           }}
                        </span>
                     </div>
                  </div>
               </div>

               <div class="mb-2 ms-4 mt-3">
                  <div class="grid grid-cols-12">
                     <div class="py-4 col-span-4 text-[11px] font-medium">
                        Sessions Details
                     </div>
                     <div class="py-4 col-span-2 text-[11px] font-medium">
                        Booking Date
                     </div>
                     <div class="py-4 col-span-2 text-[11px] font-medium">
                        Session Date
                     </div>
                     <div class="py-4 col-span-2 text-[11px] font-medium">
                        Package
                     </div>
                     <div
                        class="py-4 pe-4 col-span-2 text-[11px] font-medium text-right"
                     >
                        Service Price
                     </div>
                  </div>

                  <!-- Table Body -->
                  <div class="grid grid-cols-12">
                     <div class="pb-4 col-span-4 text-[11px] text-gray-600">
                        {{ invoiceDetail?.session?.name }}
                     </div>
                     <div class="pb-4 col-span-2 text-[11px] text-gray-600">
                        {{
                           dayjs(invoiceDetail?.session?.booking_date).format(
                              'DD MMMM, YYYY'
                           )
                        }}
                     </div>
                     <div class="pb-4 col-span-2 text-[11px] text-gray-600">
                        {{
                           dayjs(invoiceDetail?.session?.session_date).format(
                              'DD MMMM, YYYY'
                           )
                        }}
                     </div>
                     <div class="pb-4 col-span-2 text-[11px] text-gray-600">
                        {{ invoiceDetail?.session?.purchase_from }}
                     </div>
                     <div
                        class="pb-4 col-span-2 pe-4 text-[11px] text-gray-600 text-right"
                     >
                        {{ invoiceDetail?.session?.price }}
                     </div>
                  </div>
               </div>
               <div
                  class="flex border-t border-gray-100 border-opacity-40 me-2 pt-2 sm:justify-end"
               >
                  <div class="sm:w-1/3 w-full">
                     <div
                        class="flex justify-between pb-2 border-gray-100 border-opacity-40 items-center border-b"
                     >
                        <span
                           class="py-2 font-medium text-xs text-gray-1000 px-2"
                           >Subtotal</span
                        >
                        <span
                           class="py-2 px-2 text-xs text-gray-600 text-right"
                           >{{ invoiceDetail?.summary?.subtotal }}</span
                        >
                     </div>
                     <div
                        class="flex justify-between pb-2 items-center border-gray-100 border-opacity-40 border-b pt-2"
                     >
                        <span
                           class="py-2 font-medium text-xs text-gray-1000 ps-2"
                           >Tax</span
                        >
                        <span
                           class="py-2 px-2 text-xs text-gray-600 text-right"
                           >{{ invoiceDetail?.summary?.tax }}</span
                        >
                     </div>
                     <div
                        class="pt-2 flex text-xs justify-between text-primary-600 items-center"
                     >
                        <span class="pt-2 font-medium px-2">Total</span>
                        <span class="pt-2 px-2 text-right">
                           {{ invoiceDetail?.summary?.total }}
                        </span>
                     </div>
                  </div>
               </div>

               <div
                  class="text-gray-600 ms-4 -pb-3 border-gray-100 border-opacity-40 border-b text-left text-xs"
               >
                  <!-- <div class="font-medium text-gray-1000 text-sm">
                  Thank you for trusting Talktime!
               </div> -->
                  <CommonIsoStandard align="left"></CommonIsoStandard>
               </div>
               <div
                  class="pt-4 ps-4 pb-2 sm:overflow-auto overflow-x-scroll text-gray-700 flex text-xs font-medium justify-between"
               >
                  <div class="text-xs">Talktime, Dubai UAE</div>
                  <div>+971 4 836 9411 | customercare@talktime.ae</div>
               </div>
            </div>
         </div>
         <div class="mb-6 sm:order-2 col-span-12 sm:col-span-3 text-center">
            <div class="pb-3 px-3 mt-3 shadow-md">
               <div class="my-4">Get a PDF Copy</div>
               <BaseButton
                  @click="downloadPDF"
                  variant="outlined"
                  color="black"
                  block
                  class="mx-auto"
               >
                  <ArrowDownOnSquareIcon
                     class="h-5 w-8"
                  ></ArrowDownOnSquareIcon>
                  <span class="text-gray-1000 text-sm font-medium ms-2">
                     Download PDF
                  </span>
               </BaseButton>
            </div>
            <div
               class="my-6 border bg-green-500 bg-opacity-5 rounded-md text-left p-3 text-xs border-green-500 text-green-500 items-start flex gap-3"
            >
               <CheckCircleIcon
                  class="h-10 w-10 -mt-2 text-green-500"
               ></CheckCircleIcon>
               <div>
                  You are eligible to reimburse through your Insurance provider.
               </div>
            </div>
            <!-- <div class="text-left">
               <ClientReimbursableGuideDialog></ClientReimbursableGuideDialog>
            </div> -->
         </div>
      </div>
   </div>
</template>
<script setup>
   import html2pdf from 'html2pdf.js';
   import {
      ArchiveBoxArrowDownIcon,
      ArrowDownIcon,
      ArrowDownOnSquareIcon,
   } from '@heroicons/vue/20/solid';
   import dayjs from 'dayjs';
   import { CheckCircleIcon } from '@heroicons/vue/24/outline';
   import { useClientInfo } from '@/stores/clientInfo';
   const { clientInformation } = storeToRefs(useClientInfo());
   const emit = defineEmits(['show-list']);
   const { invoiceDetail } = storeToRefs(useReimbursableStore());
   const downloadPDF = () => {
      const element = document.getElementById('invoice-content');
      const opt = {
         margin: 0.2,
         filename: 'invoice.pdf',
         image: { type: 'jpeg', quality: 0.98 },
         html2canvas: { scale: 2 },
         jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf().set(opt).from(element).save();
   };
</script>
